require('../libs/jquery')

require('jquery-ujs/src/rails')
require('metismenu/dist/metisMenu')

require('../plugins/all')

$(function() {
  selectExportTableOption();
});

function selectExportTableOption() {
  $('#toolbar').find('select').on('change', function() {
    $('#table').bootstrapTable('refreshOptions', {
      exportDataType: $(this).val()
    });
  });
}
